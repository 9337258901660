// eslint-disable-next-line import/prefer-default-export
export const claimsModel = [
  {
    claim: 'Recibos',
    level: 'Módulo',
    children: [
      {
        id: 'ViewPayrolls',
        claim: 'Ver recibos de nómina',
        level: 'Proceso',
        dependentOn: [
          'StampPayroll',
          'PayrollControl',
          'Reports',
          'KardexControl',
          'DeletePayroll',
          'CancelPayroll',
        ],
      },
      {
        id: 'StampPayroll',
        claim: 'Timbrar recibos de nómina',
        level: 'Proceso',
      },
      {
        id: 'PayrollControl',
        claim: 'Gestionar comprobantes',
        level: 'Proceso',
        children: [
          {
            id: 'DownloadPayroll',
            claim: 'Descargar comprobantes',
            level: 'Acción específica',
          },
          {
            id: 'SendMail',
            claim: 'Enviar comprobantes por correo',
            level: 'Acción específica',
          },
          {
            id: 'PrintPayroll',
            claim: 'Imprimir comprobantes',
            level: 'Acción específica',
          },
        ],
      },
      {
        id: 'Reports',
        claim: 'Generar reportes',
        level: 'Proceso',
        children: [
          {
            id: 'MailNotificationReport',
            claim: 'Generar Reporte de Notificaciones por correo',
            level: 'Acción específica',
          },
        ],
      },
      {
        id: 'KardexControl',
        claim: 'Gestionar kardex de recibos',
        level: 'Proceso',
        children: [
          {
            id: 'ViewPayrollKardex',
            claim: 'Ver Kardex de recibos',
            level: 'Acción específica',
            dependentOn: ['AddPerdeducConcept', 'EditPerdeducConcept'],
          },
          {
            id: 'AddPerdeducConcept',
            claim: 'Agregar concepto de nómina',
            level: 'Acción específica',
          },
          {
            id: 'EditPerdeducConcept',
            claim: 'Editar concepto de nómina',
            level: 'Acción específica',
          },
          {
            id: 'DeletePerdeducConcept',
            claim: 'Eliminar concepto de nómina',
            level: 'Acción específica',
          },
        ],
      },
      {
        id: 'DeletePayroll',
        claim: 'Eliminar recibos de nómina',
        level: 'Proceso',
      },
      {
        id: 'CancelPayroll',
        claim: 'Cancelar recibos de nómina',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Colaboradores',
    level: 'Módulo',
    children: [
      {
        id: 'ViewEmployees',
        claim: 'Ver colaboradores',
        level: 'Proceso',
        dependentOn: ['EditEmployee'],
      },
      {
        id: 'EditEmployee',
        claim: 'Editar datos generales del colaborador',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Importar nóminas',
    level: 'Módulo',
    children: [
      {
        id: 'ViewImportData',
        claim: 'Ver archivos de nómina',
        level: 'Proceso',
        dependentOn: ['AddLoadData', 'EditLoadData', 'DeleteLoadData'],
      },
      {
        id: 'AddLoadData',
        claim: 'Importar archivos de nómina',
        level: 'Proceso',
      },
      {
        id: 'EditLoadData',
        claim: 'Editar archivos de nómina',
        level: 'Proceso',
      },
      {
        id: 'DeleteLoadData',
        claim: 'Eliminar archivos de nómina',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Conf. Subcontratación',
    level: 'Módulo',
    children: [
      {
        id: 'ViewOutsourcings',
        claim: 'Ver configuración de subcontratación',
        level: 'Proceso',
        dependentOn: ['AddOutsourcing', 'EditOutsourcing', 'DeleteOutsourcing'],
      },
      {
        id: 'AddOutsourcing',
        claim: 'Agregar configuración de subcontratación',
        level: 'Proceso',
      },
      {
        id: 'EditOutsourcing',
        claim: 'Editar configuración de subcontratación',
        level: 'Proceso',
      },
      {
        id: 'DeleteOutsourcing',
        claim: 'Eliminar configuración de subcontratación',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Razones sociales',
    level: 'Catálogo',
    children: [
      {
        id: 'ViewCompanies',
        claim: 'Ver razones sociales',
        level: 'Proceso',
        dependentOn: ['AddCompany', 'EditCompany', 'DeleteCompany'],
      },
      {
        id: 'AddCompany',
        claim: 'Agregar razones sociales',
        level: 'Proceso',
      },
      {
        id: 'EditCompany',
        claim: 'Editar razones sociales',
        level: 'Proceso',
      },
      {
        id: 'DeleteCompany',
        claim: 'Eliminar razones sociales',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Certificados',
    level: 'Catálogo',
    children: [
      {
        id: 'ViewCertificates',
        claim: 'Ver Certificados de Sello Digital (CSD)',
        level: 'Proceso',
        dependentOn: ['AddCertificate', 'EditCertificate', 'DeleteCertificate'],
      },
      {
        id: 'AddCertificate',
        claim: 'Agregar Certificados de Sello Digital (CSD)',
        level: 'Proceso',
      },
      {
        id: 'EditCertificate',
        claim: 'Editar Certificados de Sello Digital (CSD)',
        level: 'Proceso',
      },
      {
        id: 'DeleteCertificate',
        claim: 'Eliminar Certificados de Sello Digital (CSD)',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Registros patronales',
    level: 'Catálogo',
    children: [
      {
        id: 'ViewEmployerRegistrations',
        claim: 'Ver Registros Patronales',
        level: 'Proceso',
        dependentOn: [
          'AddEmployerRegistration',
          'EditEmployerRegistration',
          'DeleteEmployerRegistration',
        ],
      },
      {
        id: 'AddEmployerRegistration',
        claim: 'Agregar Registros Patronales',
        level: 'Proceso',
      },
      {
        id: 'EditEmployerRegistration',
        claim: 'Editar Registros Patronales',
        level: 'Proceso',
      },
      {
        id: 'DeleteEmployerRegistration',
        claim: 'Eliminar Registros Patronales',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Unidades de negocio',
    level: 'Catálogo',
    children: [
      {
        id: 'ViewBusinessUnits',
        claim: 'Ver Unidades de negocio',
        level: 'Proceso',
        dependentOn: ['AddBusinessUnit', 'EditBusinessUnit', 'DeleteBusinessUnit'],
      },
      {
        id: 'AddBusinessUnit',
        claim: 'Agregar Unidades de negocio',
        level: 'Proceso',
      },
      {
        id: 'EditBusinessUnit',
        claim: 'Editar Unidades de negocio',
        level: 'Proceso',
      },
      {
        id: 'DeleteBusinessUnit',
        claim: 'Eliminar Unidades de negocio',
        level: 'Proceso',
      },
    ],
  },
  {
    claim: 'Compañía contratante',
    level: 'Catálogo',
    children: [
      {
        id: 'ViewEmployerCompanies',
        claim: 'Ver Compañías Contratantes',
        level: 'Proceso',
        dependentOn: ['AddEmployerCompany', 'EditEmployerCompany', 'DeleteEmployerCompany'],
      },
      {
        id: 'AddEmployerCompany',
        claim: 'Agregar Compañías Contratantes',
        level: 'Proceso',
      },
      {
        id: 'EditEmployerCompany',
        claim: 'Editar Compañías Contratantes',
        level: 'Proceso',
      },
      {
        id: 'DeleteEmployerCompany',
        claim: 'Eliminar Compañías Contratantes',
        level: 'Proceso',
      },
    ],
  },
  {
    id: 'integrations',
    claim: 'Integraciones',
    level: 'Configuración',
    disabled: true,
    children: [
      {
        id: 'ViewIntegrations',
        claim: 'Ver Integraciones',
        level: 'Proceso',
        disabled: true,
        // dependentOn: ['EditEmployee']
      },
    ],
  },
];
