<template>
  <Frame>
    <Grid
      :data="users"
      :columns="userColumns"
      :actions="actions"
      :paginated="false"
      class="custom-config-grid"
      :showAdd="false"
      customEmptyText="No hay usuarios asignados a este rol"
    />
  </Frame>
</template>

<script>
export default {
  name: 'ConfigRoleViewUsers',
  data() {
    return {
      actions: [
        {
          text: 'Desasignar rol',
          callback: this.deleteUserRole,
        },
      ],
      userColumns: [
        {
          title: 'Cuenta',
          field: 'userName',
          searchable: true,
        },
        {
          title: 'Usuario',
          template: (item) => this.getUserName(item),
        },
      ],

      dataSelector: null,
      users: [],
      totalAssigned: 0,
    };
  },

  created() {
    this.getUsersByRole();
    this.$root.$on('reloadData', () => {
      this.getUsersByRole();
    });
  },
  methods: {
    getUsersByRole() {
      const { id } = this.$route.params;
      this.getAll('Configuration/Users', `${id}`, 'stamp').then((data) => {
        this.users = data;
      });
    },
    getUserName(item) {
      let name = `${item.firstName} `;
      if (item.middleName) name += `${item.middleName} `;
      name += `${item.lastName1} `;
      if (item.lastName2) name += item.lastName2;

      return name;
    },
    deleteUserRole(item) {
      this.showConfirm(
        '¿Deseas desasignarle el rol al usuario?',
        'Al desasignarle el rol al usuario este perderá acceso a los módulos, catálogos y funciones, a los que tiene acceso.',
        'danger',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: { color: 'terciary-outlined' },
          },
          {
            text: 'Desasignar permisos',
            callback: () => {
              // this.setNotification('El rol le ha sido desasignado al usuario exitosamente.', 'success', 'Rol desasignado exitosamente.')
              this.delete('Configuration/UserRole', item.userID, 'stamp');
            },
            props: {
              color: 'quinary-outlined',
            },
          },
        ]
      );
    },
  },
};
</script>

<style lang="scss">
.read-info {
  vertical-align: center;

  .mdi {
    margin-top: 20px;
    padding: 5px;
    top: -10px;
    color: #5185fe;
    font-size: 18px;
  }
}

.none-assigned {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 7px;
  background-color: #dce7ff;

  span {
    //icon
    font-size: 120%;
    color: #5185fe;
    position: relative;
    display: inline;
    vertical-align: baseline;
    margin: 0 0.125em;
    padding: 0;
    span {
      color: #707070;
      font-size: 14px !important;
      vertical-align: middle;
      a {
        text-decoration: none;
        color: #707070;
        font-weight: 600;
        &:hover {
          color: #5185fe;
          text-decoration: underline;
        }
      }
    }
  }
}

.custom-config-grid {
  .table-actions {
    margin-bottom: 15px;
  }
  .pw-datagrid {
    &__head {
      th {
        background-color: #fbfbfb;
      }
    }
    &__body {
      .pw-datagrid__row {
        td {
          background-color: #fff;
        }
      }
    }
  }
}

.is-color10 {
  background-color: #294380;
  color: #fefefe;
}
</style>
