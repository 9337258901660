<template>
  <Frame :loading="loading" title="Configuración" titleAction="Roles">
    <div slot="actions">
      <span @click="goBack" class="close-frame">
        <i class="mdi mdi-close"></i>
      </span>
    </div>
    <div v-if="roleData">
      <Title
        :description="`Descripción: ${roleData.description}`"
        :title="roleData.roleName"
        @clickAction="onEdit"
        @reloadData="getRoleInfo"
        actionText="Editar"
      />
      <TabStrip>
        <TabStripItem title="Permisos">
          <Column class="none-assigned" size="12" v-if="roleData.claimsCount === 0">
            <span class="mdi mdi-information">
              <span>
                Aún no le ha sido asignado ningún permiso a este rol. Selecciona los permisos que
                deseas asignarle y da clic en "asignar permisos" para otorgárselos.
              </span>
            </span>
          </Column>
          <GridSelector
            :columns="columns"
            :data="dataSelector"
            @getValues="saveClaims"
            actionIcon="shield-key-outline"
            actionSelectedWord="Permiso"
            customPlaceholder="Buscar permisos"
            ref="claimsGrid"
            widthLastRows="20%"
          >
            <label class="read-info" slot="readInfo" v-show="roleData.claimsCount > 0">
              <span class="mdi mdi-information"></span>
              <span class="read-info__text">
                Este rol cuenta con {{ roleData.claimsCount }} permisos asignados.
              </span>
            </label>
          </GridSelector>
        </TabStripItem>
        <TabStripItem title="Usuarios">
          <ConfigRoleViewUsers />
        </TabStripItem>
      </TabStrip>
    </div>
    <Modal :active.sync="openModal" class="modal-with-overflow">
      <ConfigRoleForm :modelToEdit="modelToEdit" />
    </Modal>
  </Frame>
</template>

<script>
import ConfigRoleViewUsers from './ConfigRoleViewUsers.vue';
import ConfigRoleForm from './ConfigRoleForm.vue';
import { claimsModel } from './ClaimsModel';

export default {
  name: 'ConfigRoleView',
  components: { ConfigRoleViewUsers, ConfigRoleForm },
  data() {
    return {
      roleData: null,
      columns: [
        {
          title: 'Permiso',
          field: 'claim',
        },
        {
          title: 'Nivel',
          template: this.getLevel,
        },
        {
          title: 'Estatus',
          template: this.getStatus,
        },
      ],
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
        {
          text: 'Editar',
          callback: this.onEdit,
        },
      ],
      dataSelector: null,
      users: null,
      totalAssigned: 0,
      modelToEdit: null,
      openModal: false,
      loading: true,
    };
  },
  mounted() {
    this.getRoleInfo();
    this.$root.$on('reloadData', () => {
      this.getRoleInfo();
    });
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'config', query: { p: 'roles' } });
    },
    onEdit() {
      this.modelToEdit = {
        RoleID: this.$route.params.id,
        RoleName: this.roleData.roleName,
        Description: this.roleData.description,
      };
      this.openModal = true;
    },
    getAssigned(total) {
      this.totalAssigned = total;
      return total !== 0;
    },
    getRoleInfo() {
      this.loading = true;
      this.dataSelector = claimsModel;

      const { id } = this.$route.params;
      if (!id) return;
      this.getAll('Configuration/Role', `${id}`, 'stamp')
        .then((data) => {
          requestAnimationFrame(() => {});
          this.$nextTick(() => {
            this.roleData = data;
            this.loading = false;
            this.setSelected(this.dataSelector);
            if (this.$refs.claimsGrid) {
              this.$refs.claimsGrid.resetInitialSelectors(this.dataSelector);
            }
          });
        })
        .catch((error) => {
          this.setNotification(error, 'warning');
          this.loading = false;
        });
    },
    getLevel(item) {
      const levels = [
        {
          text: 'Módulo',
          color: 'is-secondary',
        },
        {
          text: 'Proceso',
          color: 'is-color4',
        },
        {
          text: 'Acción específica',
          color: 'is-color9',
        },
        {
          text: 'Catálogo',
          color: 'is-color8',
        },
        {
          text: 'Configuración',
          color: 'is-color10',
        },
      ];

      const level = levels.find((x) => x.text === item.level);
      return `<span class="pw-tag ${level.color}">${level.text}</span>`;
    },
    getStatus(item) {
      const text = item.status === 'active' ? 'Activo' : 'Inactivo';
      const color = `is-${item.status === 'active' ? 'color3' : 'terciary'}`;

      return `<span class="pw-tag ${color}">${text}</span>`;
    },
    setSelected(model) {
      const currentClaims = this.roleData.claimsSelected;
      let someSelected = false;

      model.forEach((node) => {
        let childrenSelected = false;
        const selected = currentClaims.includes(`STM.${node.id}`);
        someSelected = someSelected || selected;
        node.selected = selected;

        if (node.children) {
          childrenSelected = this.setSelected(node.children);
        }
        const status = selected || childrenSelected ? 'active' : 'inactive';
        node.status = status;
      });
      return someSelected;
    },
    saveClaims(selectors) {
      // (selectors)

      selectors = selectors.map((s) => `STM.${s}`);
      const claimsToSave = {
        RoleID: this.$route.params.id,
        RoleClaims: selectors,
      };
      this.showConfirm(
        '¿Deseas asignarle permisos al rol?',
        'Al asignarle permisos al rol, los colaboradores que cuenten con él se verán afectados, asignando o limitando las funciones, catálogos y módulos a los que tienen acceso.',
        'information',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: {
              color: 'terciary-outlined',
            },
          },
          {
            text: 'Asignar permisos',
            callback: () => {
              this.save(
                'Configuration/roleClaims',
                claimsToSave,
                'stamp',
                'RoleID',
                'Permisos guardados exitosamente'
              ).then((data) => {
                if (data) {
                  this.$root.$emit('reloadClaims');
                }
              });
            },
            props: {
              color: 'secondary-outlined',
            },
          },
        ]
      );
    },
  },
};
</script>

<style lang="scss">
.read-info {
  vertical-align: center;

  .mdi {
    margin-top: 20px;
    padding: 5px;
    top: -10px;
    color: #5185fe;
    font-size: 18px;
  }
}

.none-assigned {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 7px;
  background-color: #dce7ff;

  span {
    //icon
    font-size: 120%;
    color: #5185fe;
    position: relative;
    display: inline;
    vertical-align: baseline;
    margin: 0 0.125em;
    padding: 0;

    span {
      color: #707070;
      font-size: 14px !important;
      vertical-align: middle;

      a {
        text-decoration: none;
        color: #707070;
        font-weight: 600;

        &:hover {
          color: #5185fe;
          text-decoration: underline;
        }
      }
    }
  }
}

.custom-config-grid {
  .table-actions {
    margin-bottom: 15px;
  }

  .pw-datagrid {
    &__head {
      th {
        background-color: #fbfbfb;
      }
    }

    &__body {
      .pw-datagrid__row {
        td {
          background-color: #fff;
        }
      }
    }
  }
}

.is-color10 {
  background-color: #294380;
  color: #fefefe;
}
</style>
